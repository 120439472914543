import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Layout, SectionContainer, SectionTitle } from "../components/_layout"
import { Contact } from "../components/ContactModule"
import { RichTextRenderer } from "../components/_common"

const getPageData = graphql`
  query {
    legalData: allContentfulContenidoSeccionesPrivacidad {
      edges {
        node {
          id
          titulo
          contenido {
            raw
          }
        }
      }
    }
  }
`

export interface ILegalContent {
  id: string
  titulo: string
  contenido: string
}

const LEGAL: React.FC = () => {
  const { legalData } = useStaticQuery(getPageData)
  const legal: ILegalContent = getSectionData(legalData)

  return (
    <Layout withNav seoTitle="Legal" seoDescription="Legal y privacidad">
      <SectionContainer sectionID="legal">
        <SectionTitle title={legal.titulo} />
        <RichTextRenderer content={legal.contenido} />
      </SectionContainer>
      <Contact />
    </Layout>
  )
}

export default LEGAL

const getSectionData = (arr: any) =>
  arr.edges.map(({ node }: any) => ({
    id: node.id,
    titulo: node.titulo,
    contenido: node.contenido.raw,
  }))[0]
